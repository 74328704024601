<template>
	<el-dialog :visible.sync="showModel" :close-on-click-modal="false">
		<div id="mapAdd" style="width: 100%; height: 500px"></div>
	</el-dialog>
</template>

<script>
	import { forEach } from "jszip";
	// import {getTrackWorkPage} from "@/RequestPort/checkingIn";
	export default {
		name: "workLocus",
		data() {
			return {
				showModel: false,
				wms: null,
				// 轨迹
      polyline: null,
			};
		},
		mounted() {
			// (type='line' and id in (1)) or (type='point' and id in (1))
		},
		methods: {
			open(row, val) {
				let that = this;
				this.showModel = true;
				this.$nextTick(() => {
					that.map = new AMap.Map("mapAdd", {
						resizeEnable: true,
						center: val[0].trackPathPointList[0],
						zoom: 14,
					});

					// 巡检轨迹（旧）
					// if (val && val.length > 0) {
					// 	for (let i = 0; i < val.length; i++) {
					// 		let polyline = new AMap.Polyline({
					// 			path: val[i].trackPathPointList,
					// 			isOutline: true,
					// 			outlineColor: "#ffeeff",
					// 			borderWeight: 1,
					// 			strokeColor: "#000000",
					// 			strokeOpacity: 1,
					// 			strokeWeight: 2,
					// 			// 折线样式还支持 'dashed'
					// 			strokeStyle: "solid",
					// 			// strokeStyle是dashed时有效
					// 			strokeDasharray: [10, 5],
					// 			lineJoin: "round",
					// 			lineCap: "round",
					// 			zIndex: 2021,
					// 		});
					// 		that.map.add(polyline);

					// 		// 起点
					// 		// let qidian = require('@/assets/start.png')
					// 		let qidian = new AMap.Icon({
					// 			size: new AMap.Size(58, 70), // 图标尺寸
					// 			image: require("@/assets/start.png"), // Icon的图像地址
					// 			imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
					// 		});
					// 		let markerqi = new AMap.Marker({
					// 			position: new AMap.LngLat(
					// 				val[i].trackPathPointList[0][0],
					// 				val[i].trackPathPointList[0][1]
					// 			),
					// 			offset: new AMap.Pixel(-10, -30),
					// 			icon: qidian, // 添加 Icon 实例
					// 			title: "自定义图标",
					// 			zoom: 13,
					// 		});
					// 		that.map.add(markerqi);
					// 		// 终点
					// 		let zhongdian = new AMap.Icon({
					// 			size: new AMap.Size(58, 70), // 图标尺寸
					// 			image: require("@/assets/end.png"), // Icon的图像地址
					// 			imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
					// 		});
					// 		let markerZhong = new AMap.Marker({
					// 			position: new AMap.LngLat(
					// 				val[i].trackPathPointList[val[i].trackPathPointList.length - 1][0],
					// 				val[i].trackPathPointList[val[i].trackPathPointList.length - 1][1]
					// 			),
					// 			offset: new AMap.Pixel(-10, -30),
					// 			icon: zhongdian, // 添加 Icon 实例
					// 			title: "自定义图标",
					// 			zoom: 13,
					// 		});
					// 		that.map.add(markerZhong);
					// 	}
					// }
					this.polyline=new AMap.OverlayGroup()
					 if (val[0].smoothTrackList && val[0].smoothTrackList.length > 0) {
					for (let i = 0; i < val[0].smoothTrackList.length; i++) {
						// 起点
							let qidian = new AMap.Icon({
								size: new AMap.Size(58, 70), // 图标尺寸
								image: require("@/assets/start.png"), // Icon的图像地址
								imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
							});
							let markerqi = new AMap.Marker({
								position: new AMap.LngLat(
									val[0].smoothTrackList[i][0][0],
                					val[0].smoothTrackList[i][0][1]
								),
								offset: new AMap.Pixel(-10, -30),
								icon: qidian, // 添加 Icon 实例
								title: "自定义图标",
								zoom: 13,
							});
							that.map.add(markerqi);
						let arr=[]
						for (let j = 0; j < val[0].smoothTrackList[i].length; j++) {
						if (j == val[0].smoothTrackList[i].length - 1) {
							// 终点
							let lastLineLength = val[0].smoothTrackList[i][j].length
							let zhongdian = new AMap.Icon({
								size: new AMap.Size(58, 70), // 图标尺寸
								image: require("@/assets/end.png"), // Icon的图像地址
								imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
							});
							let markerZhong = new AMap.Marker({
								position: new AMap.LngLat(
									val[0].smoothTrackList[i][lastLineLength-1][0],
                    				val[0].smoothTrackList[i][lastLineLength-1][1]
								),
								offset: new AMap.Pixel(-10, -30),
								icon: zhongdian, // 添加 Icon 实例
								title: "自定义图标",
								zoom: 13,
							});
							that.map.add(markerZhong);
						}
						let polyline = new AMap.Polyline({
							path: val[0].smoothTrackList[i],
							isOutline: true,
							outlineColor: "#ffeeff",
							borderWeight: 1,
							strokeColor: "#409EFF",
							strokeOpacity: 1,
							strokeWeight: 6,
							// 折线样式还支持 'dashed'
							strokeStyle: "solid",
							// strokeStyle是dashed时有效
							strokeDasharray: [10, 5],
							lineJoin: "round",
							lineCap: "round",
							zIndex: 2021,
							showDir:true,
						});
						arr.push(polyline)
						// that.map.add(polyline);
						}
						that.polyline.addOverlays(arr)
						that.map.add(this.polyline);
					}
					}
					let sql = "1=1";
					let equipment = "";
					let line = "";
				});
			},
		},
	};
</script>